import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Newsletter from '../components/preInscricao';

const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <main>
                {/* LANDING PAGE */}
                <div className="flex h-[86vh]">
                    <div className="flex flex-col items-center justify-center flex-grow px-8 bg-center bg-cover gap-y-14 lg:flex-row lg:px-24" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/mainImage.jpg)` }}>

                        <div className="flex flex-col p-10 px-4 bg-black rounded-lg backdrop-blur-sm sm:backdrop-blur-md bg-opacity-40 lg:w-1/2">
                            <h1 className="text-left title">
                                Escola de condução Andanças
                            </h1>
                            <h6 className="mt-4 text-lg font-semibold tracking-wide text-left text-white lg:text-2xl">
                                Possibilidade de tirar a carta de ligeiros em 80 dias
                            </h6>
                            <p className="mt-4 text-left subsubtitle">
                                Possibilidade de código online
                            </p>
                            <div className="mt-8 ">
                                <Link to={"/contacto"} className="CTA" >Contacta-nos</Link>
                            </div>
                        </div>
                        <div className="lg:w-1/2 lg:mt-0">
                        </div>
                    </div>
                </div>
                {/* LANDING PAGE END*/}
                <div className='px-8 lg:px-24'>


                    {/* CARDS */}
                    <div className="relative z-10 grid grid-cols-1 mt-10 gap-y-5 gap-x-16 sm:grid-cols-3">
                        <div onClick={() => navigate("/ensino")} className={`cardContainer group`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/cards/Intrutor-conducao-1.jpg)` }}>
                            <div className="cardFilter"></div>
                            <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                                <h3 className="cardTitle ">
                                    Ensino
                                </h3>
                            </div>
                        </div>
                        <div onClick={() => navigate("/parceiros")} className={`cardContainer group bg-center`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/cards/parceiros.jpg)` }}>
                            <div className="cardFilter"></div>
                            <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                                <h3 className="cardTitle">
                                    Parceiros
                                </h3>
                            </div>
                        </div>
                        <div onClick={() => navigate("/servicos")} className={`cardContainer group`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/cards/servicos-equipa.jpg)` }}>
                            <div className="cardFilter"></div>
                            <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                                <h3 className="cardTitle">
                                    Serviços
                                </h3>
                            </div>
                        </div>
                    </div>
                    {/*  CARDS END*/}
                    <div className="flex flex-col pt-16 lg:flex-row">
                        <div className="text-black lg:w-1/2 dark:text-white">
                            <div className="w-[90%]">
                                <h2 className="smallTitle">Missão</h2>
                                <p className="mt-2">Sustentabilidade do negócio, aperfeiçoamento constante, com o objetivo de corresponder às expectativas dos nossos clientes.</p>
                            
								<p className="mt-1.5">
                                   ‎ 
                                </p>
							</div>
							
							
							 <div className="w-[90%]">
                                <h2 className="smallTitle">Visão</h2>
                                <p className="mt-2">Ser uma escola de condução de referência na região do Alentejo e contribuir ativamente para a segurança rodoviária.</p>
                            
								<p className="mt-1.5">
                                   ‎ 
                                </p>
							</div>
							
							
							 <div className="w-[90%]">
                                <h2 className="smallTitle">Valores</h2>
                                <p className="mt-2">Confiança, transparência, responsabilidade, sustentabilidade e satisfação do cliente.</p>
                           

									<p className="mt-1.5">
                                   ‎ 
                                </p>

						   </div>
						
							
							
							
                            <div className="mt-4 lg:ml-10">
                                <h2 className="subtitle">Os nossos colaboradores</h2>
                                <p className="mt-1.5">
                                    <span className="font-bold">Eduardo Matos </span>
                                    – Diretor e instrutor.
                                </p>
								<p className="mt-1.5">
                                    <span className="font-bold">Ana Reis </span>
                                    – Instrutora.
                                </p>
								<p className="mt-1.5">
                                    <span className="font-bold">Cecília Mendes </span>
                                    – Instrutora.
                                </p>
								<p className="mt-1.5">
                                    <span className="font-bold">Pedro Pires </span>
                                    – Instrutor.
                                </p>
                                <p className="mt-1.5">
                                    <span className="font-bold">João Pinto </span>
                                    – Instrutor.
                                </p>
                                <p className="mt-1.5">
                                    <span className="font-bold">Rui Patriarca </span>
                                    – Instrutor.
                                </p>

								<p className="mt-1.5">
                                    <span className="font-bold">Paula Santos </span>
                                    – Instrutora.
                                </p>
                                <p className="mt-1.5">
                                    <span className="font-bold">Fernanda Reis </span>
                                    – Administrativa.
                                </p>
                                <p className="mt-1.5">
                                    <span className="font-bold">João Santos </span> – Gerência
                                </p>
                            </div>
                        </div>
                        {/* TABLES */}
                        <div className="flex flex-col items-center mt-16 text-black lg:w-1/2 lg:mt-0 dark:text-white">
                            <h4 className="subtitle">Percentagens de aprovações - 2023</h4>
                            <div className="flex flex-col gap-10 mt-4 sm:flex-row">
                                <div>
                                    <h5 className="font-bold text-center">Exames Teóricos</h5>
                                    <div className="mt-2 overflow-x-auto rounded-lg shadow-md">
                                        <table className="w-full text-sm">
                                            <thead className="tableHead">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Categoria
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        % de Aprovação
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        A
                                                    </th>
                                                    <td className="tableDataCell">
                                                        100%
                                                    </td>
                                                </tr>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        A1
                                                    </th>
                                                    <td className="tableDataCell">
                                                        85%
                                                    </td>
                                                </tr>
												<tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        A2
                                                    </th>
                                                    <td className="tableDataCell">
                                                        90%
                                                    </td>
                                                </tr>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        B
                                                    </th>
                                                    <td className="tableDataCell">
                                                        80%
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="font-bold text-center">Exames Práticos</h5>
                                    <div className="mt-2 overflow-x-auto rounded-lg shadow-md">
                                        <table className="w-full text-sm">
                                            <thead className="tableHead">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Categoria
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        % de Aprovação
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        B
                                                    </th>
                                                    <td className="tableDataCell">
                                                        80%
                                                    </td>
                                                </tr>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        B+E
                                                    </th>
                                                    <td className="tableDataCell">
                                                        100%
                                                    </td>
                                                </tr>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        A1
                                                    </th>
                                                    <td className="tableDataCell">
                                                        85%
                                                    </td>
                                                </tr>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        A2
                                                    </th>
                                                    <td className="tableDataCell">
                                                        100%
                                                    </td>
                                                </tr>
                                                <tr className="tableRow">
                                                    <th scope="row" className="tableDataCell">
                                                        A
                                                    </th>
                                                    <td className="tableDataCell">
                                                        100%
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* TABLES END */}
                    </div>
                    <Newsletter />
                </div>
            </main >
        </>
    );
};
export default Home;