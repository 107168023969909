import React, { useEffect, useState } from 'react';
import { FaMoon, FaPhone, FaSun } from "react-icons/fa6";
import { MdEmail, MdHome, MdOutlineMenu } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';

const Navbar = ({ currentPage }) => {
    const navigate = useNavigate();
    const [userTheme, setUserTheme] = useState(localStorage.getItem("theme"));
    const [systemTheme, setSystemTheme] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches);
    const [moonIcon, setMoonIcon] = useState(null);
    const [sunIcon, setSunIcon] = useState(null);
	const [logoSrc, setLogoSrc] = useState(process.env.PUBLIC_URL + '/images/logo1.png'); // Default logo

    useEffect(() => {
        const moon = document.getElementsByClassName('moon');
        const sun = document.getElementsByClassName('sun');
        setMoonIcon(moon);
        setSunIcon(sun);
        themeCheck(moon, sun);
    }, []);

    const themeCheck = (moonIcon, sunIcon) => {
        if (userTheme === 'dark' || (!userTheme && systemTheme)) {
            document.documentElement.classList.add('dark');

            sunIcon[0].classList.remove("hidden");
            sunIcon[1].classList.remove("hidden");
            return;
        }
        moonIcon[0].classList.remove("hidden");
        moonIcon[1].classList.remove("hidden");
    }

    const themeSwitch = () => {
        if (document.documentElement.classList.contains('dark')) {
            document.documentElement.classList.remove("dark");
            localStorage.setItem("theme", "light");
			setLogoSrc(process.env.PUBLIC_URL + '/images/logo.png'); // Light mode logo

        } else {
            document.documentElement.classList.add("dark");
            localStorage.setItem("theme", "dark");
			setLogoSrc(process.env.PUBLIC_URL + '/images/logo1.png'); // Light mode logo

        }
        iconToggle();
    }

    const iconToggle = () => {
        sunIcon[0].classList.toggle("hidden");
        sunIcon[1].classList.toggle("hidden");
        moonIcon[0].classList.toggle("hidden");
        moonIcon[1].classList.toggle("hidden");
    }

    const hamburguerClicked = () => {
        let mobileMenu = document.getElementById("mobileMenu");
        let closeMobileMenuDiv = document.getElementById("closeMobileMenuDiv");

        mobileMenu.classList.toggle("hidden");
        mobileMenu.classList.toggle("block");

        closeMobileMenuDiv.classList.toggle("hidden");
        closeMobileMenuDiv.classList.toggle("block");

    }


    return (
        <div className="top-0 z-50 lg:sticky ">
            {/*  TOP BANNER */}
            <div className="bg-slate-300 px-10 py-1.5 dark:bg-gray-950 lg:flex justify-between hidden">
                <div className="flex gap-10">
                    <div className="navbarItem">
                        <MdHome className="w-6 h-6 mr-1 text-red-600" />
                        R. Dom Nuno Álvares Pereira 162C, 7570-239 Grândola
                    </div>
                    <div className="navbarItem">
                        <MdEmail className="w-6 h-6 mr-1 text-red-600" />
                        isgrandola@gmail.com
                    </div>
                </div>
                <div className="flex gap-10">
                    <div className="navbarItem">
                        <FaPhone className="w-5 h-6 mr-1 text-red-600" />
                        269 456 134
                    </div>
                </div>
            </div>
            {/*  TOP BANNER END*/}

            {/* NAVBAR */}
            <nav className="z-10 flex items-center justify-between px-10 py-5 bg-slate-200 dark:bg-slate-900">
                <img onClick={() => navigate("/")} className="w-24 cursor-pointer lg:w-32" src={logoSrc} alt="logo" />
                {/* MOBILE MENU */}

                <div id="mobileMenu" className="hidden lg:hidden fixed top-0 bottom-0 left-0 w-[220px] sm:w-[320px] shadow-2xl transition-all bg-slate-300 dark:bg-gray-900 z-50">
                    <div className='relative h-full'>
                        <img onClick={() => navigate("/")} className="w-24 py-5 ml-10 select-none" src={logoSrc} alt="logo" />
                        <div className="flex flex-col gap-5 items-left">
                           
						  <Link 
  to={"/"} 
  className={`flex items-center justify-center navbarLink ${currentPage === "/" ? "active font-bold" : "text-black dark:text-white"}`} 
  style={{ color: currentPage === "/" ? '#f5494c' : undefined }}
>
  Início
</Link>
								
                            <Link to={"/ensino"} className={"flex items-center justify-center navbarLink " + (currentPage.includes("/ensino") ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                                Ensino
                            </Link>
                            <Link to={"/parceiros"} className={"flex items-center justify-center navbarLink " + (currentPage === "/parceiros" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                                Parceiros
                            </Link>
                            <Link to={"/servicos"} className={"flex items-center justify-center navbarLink " + (currentPage === "/servicos" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                                Serviços
                            </Link>
                            <Link to={"/FAQ"} className={"navbarLink justify-center " + (currentPage === "/FAQ" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                                FAQ
                            </Link>
                            <Link to={"/contacto"} className={"navbarCTA " + (currentPage === "/contacto" ? "text-brandBlue font-bold" : "text-black dark:text-white")}>
                                Entra em contacto
                            </Link>
                        </div>

                        <span onClick={() => themeSwitch()} className='absolute bottom-0 w-6 my-10 transform -translate-x-1/2 cursor-pointer left-1/2 '>
                            <FaSun className="hidden w-6 h-8 text-white sun" />
                            <FaMoon className="hidden w-6 h-8 text-red-600 moon" />
                        </span>
                    </div>

                </div>
                <div id='closeMobileMenuDiv' className='fixed z-10 hidden w-full h-screen lg:hidden top-20' onClick={() => hamburguerClicked()}>
                </div>
                {/* MOBILE MENU End*/}

                {/* Center Menu */}

                <div className="items-center hidden gap-8 lg:flex ">
                    <Link to={"/"} className={"flex items-center justify-center navbarLink " + (currentPage === "/" ? "text-red-500 font-bold" : "text-black dark:text-white")}style={currentPage === "/" ? { color: '#f5494c' } : {}}>
                        Início
                    </Link>
                    <Link to={"/ensino"} className={"flex items-center navbarLink " + (currentPage.includes("/ensino") ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                        Ensino
                    </Link>
                    <Link to={"/parceiros"} className={"flex items-center navbarLink " + (currentPage === "/parceiros" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                        Parceiros
                    </Link>
                    <Link to={"/servicos"} className={"flex items-center navbarLink " + (currentPage === "/servicos" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                        Serviços
                    </Link>
                    <Link to={"/FAQ"} className={"navbarLink " + (currentPage === "/FAQ" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                        FAQ
                    </Link>
                    <Link to={"/contacto"} className={"navbarCTA " + (currentPage === "/contacto" ? "text-red-500 font-bold" : "text-black dark:text-white")}>
                        Entra em contacto
                    </Link>
                </div>
                {/* Center Menu END*/}

                {/* Switch Mode */}
                <div className="hidden lg:block">
                    <span onClick={() => themeSwitch()} className='cursor-pointer'>
                        <FaSun className="hidden w-6 h-8 text-white sun" />
                        <FaMoon className="hidden w-6 h-8 text-red-600 moon" />
                    </span>
                </div>
                {/* Switch Mode END*/}

                {/* Open mobile menu */}
                <button className="text-black lg:hidden dark:text-white" onClick={() => hamburguerClicked()}>
                    <MdOutlineMenu className='w-6 h-6' />
                </button>
                {/* Open mobile menu END*/}
            </nav>
            {/* NAVBAR END */}
        </div>
    );
};

export default Navbar;