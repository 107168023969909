import React from 'react';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

const preInscricao = () => {

  const sendEmail = (e) => {

    e.preventDefault();
    let firstname = document.getElementById("firstName").value;
    let lastname = document.getElementById("lastName").value;
    let phone = document.getElementById("phone").value;
    let email = document.getElementById("email").value;

    if (firstname && lastname && phone && email) {

      emailjs.init("ir0cxmRC7CIfwQY8S");

      emailjs.send('service_f0kibgf', 'template_2ugk2qk', {
        from_name: 'Your Name',
        to_name: 'Recipient Name',
        message: `Primeiro Nome: ${firstname}
        Apelido: ${lastname}
                Email:${email} 
                Telemóvel:${phone}`
      }).then(function (response) {
        toast.success("Pré Incrição realizada com sucesso!")
      }).catch(function (error) {
        console.error('Email sending failed:', error);
      });
    } else {
      toast.error("Preencha todos os campos.")
    }


  };

  return (
    <div className="py-16">
      <form className="flex flex-col justify-center mx-auto text-center sm:w-2/3 lg:w-1/2 lg:text-left">
        <h1 className="text-center titlle">Pré Inscrição!</h1>
        <p className="mt-5 text-lg text-left text-black lg:text-center dark:text-white">
          Queres aprender a conduzir com segurança e ficar a par de todas as atualizações sobre o código da estrada? Faz a tua pré-inscrição na nossa escola de condução e começa a tua jornada para te tornares um condutor informado e confiante!
        </p>
        <input id="firstName" className="w-full p-2 mx-auto mt-10 inputField" type="text" placeholder="Insere o teu primeiro nome" required />
        <input id="lastName" className="w-full p-2 mx-auto mt-4 inputField" type="text" placeholder="Insere o teu apelido" required />
        <input id="phone" className="w-full p-2 mx-auto mt-4 inputField" type="number" placeholder="Insere o teu número de telemóvel" required />
        <input id="email" className="w-full p-2 mx-auto mt-4 inputField" type="email" placeholder="Insere o teu Email" required />

        <a className="mx-auto mt-5 cursor-pointer CTA" onClick={(e) => sendEmail(e)}>Inscrever</a>
      </form>
    </div>
  );
};

export default preInscricao;


