import './index.css';
import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

//Pages
import Home from './pages/Home';
import Ensino from './pages/Ensino';
import Servicos from './pages/Serviços';
import FAQ from './pages/FAQ';
import Contacto from './pages/Contacto';
import Motociclos from './pages/Ensino/Motociclos';
import Ligeiros from './pages/Ensino/Ligeiros';
import Pesados from './pages/Ensino/Pesados';
import Aluno from './pages/Parceiros';

//Components
import Navbar from './components/navbar';
import Footer from './components/footer';
import { Toaster } from 'react-hot-toast';

function App() {

  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <>
      <Toaster />
      <Navbar currentPage={currentRoute} />
      <div className='bg-gray-50 dark:bg-gray-800'>
        <div className='max-w-[1920px] mx-auto'>
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/ensino' exact element={<Ensino />} />
            <Route path='/Parceiros' exact element={<Aluno />} />
            <Route path='/servicos' exact element={<Servicos />} />
            <Route path='/faq' exact element={<FAQ />} />
            <Route path='/contacto' exact element={<Contacto />} />
            <Route path='/ensino/motociclos' exact element={<Motociclos />} />
            <Route path='/ensino/ligeiros' exact element={<Ligeiros />} />
            <Route path='/ensino/pesados' exact element={<Pesados />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;