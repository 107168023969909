import React from 'react';
import Newsletter from '../components/preInscricao';
import { useNavigate } from 'react-router-dom';

const Ensino = () => {
    const navigate = useNavigate();

    return (
        <>
            <main className='px-8 lg:px-24'>
                <h1 className="pt-6 text-center title">
                    Ensino
                </h1>
                {/* CARDS */}
                <div className="relative z-10 flex flex-wrap justify-center mt-12 space-x-0 space-y-5 sm:space-x-5 sm:space-y-0">
                    <div
                        onClick={() => navigate("/ensino/motociclos")}
                        className={`cardContainer bg-cover group bg-center w-full sm:w-1/2 md:w-1/3 lg:w-1/3 h-60 sm:h-80 md:h-96`}
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/motociclos/moto1.jpg)` }}
                    >
                        <div className="cardFilter"></div>
                        <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                            <h3 className="cardTitle">
                                Motociclos
                            </h3>
                        </div>
                    </div>
                    <div
                        onClick={() => navigate("/ensino/ligeiros")}
                        className={`cardContainer bg-cover group bg-center w-full sm:w-1/2 md:w-1/3 lg:w-1/3 h-60 sm:h-80 md:h-96`}
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ligeiros/car1.jpg)` }}
                    >
                        <div className="cardFilter"></div>
                        <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                            <h3 className="cardTitle">
                                Ligeiros
                            </h3>
                        </div>
                    </div>
                </div>
                {/* CARDS END */}
                <Newsletter />
            </main>
        </>
    );
};

export default Ensino;
