import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    // State to hold the logo source
    const [logoSrc, setLogoSrc] = useState('');

    // Function to check and update the logo based on the current theme
    const updateLogoBasedOnTheme = () => {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const newLogoSrc = isDarkMode
            ? process.env.PUBLIC_URL + '/images/logo1.png' // Dark mode logo
            : process.env.PUBLIC_URL + '/images/logo.png'; // Light mode logo

        // Update the logo source only if it has changed
        if (logoSrc !== newLogoSrc) {
            console.log('Updating logo:', newLogoSrc); // Debugging log
            setLogoSrc(newLogoSrc);
        }
    };

    useEffect(() => {
        // Update the logo when the component mounts
        updateLogoBasedOnTheme();

        // Set an interval to check for theme changes every 500ms
        const intervalId = setInterval(updateLogoBasedOnTheme, 500);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [logoSrc]); // Add logoSrc as a dependency to ensure the effect updates

    return (
        <footer className="shadow bg-slate-300 dark:bg-slate-900">
            <div className="w-full px-10 py-4">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <Link
                        to={'/'}
                        className="flex items-center mb-4 space-x-3 sm:mb-0 rtl:space-x-reverse"
                    >
                        <img src={logoSrc} className="h-16" alt="Logo" />
                    </Link>
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © 2024 João Santos | All Rights Reserved.
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
