import React from 'react';
import Newsletter from '../components/preInscricao';

const Servicos = () => {

    return (
        <>
            <main className='px-8 lg:px-24'>
                <h1 className="pt-6 text-center title">
                    Serviços
                </h1>
                <div className="flex flex-col max-w-4xl gap-5 mx-auto mt-12">
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Aulas de Treino
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Lições de treino</h1>
                            <p className="mt-3 text-gray-700 dark:text-gray-300">Para além do ensino de condução para instruendos, a Escola de Condução Infante do Restelo, disponibiliza também lições de treino para pessoas que já tenham carta de condução. Se já não conduz há algum tempo ou quer aperfeiçoar a sua condução com a ajuda de profissionais não hesite!</p>
                            <ul className="flex flex-col mt-4">
                                <li className="accordionItem">O horário das aulas de treino é de segunda a sexta-feira das 7h às 21h e aos sábados das 9h às 18h.</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className="accordionItem">Atestado Médico</li>
                                <li className="accordionItem">Relatório de exame psicológico favorável</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Veículos
                        </div>
                        <div className="px-6 collapse-content sm:px-10">
                            <h1 className="subtitle">Disponibilizamos os seguintes serviços no que diz respeito ao seu veículo:</h1>
                            <ul className="flex flex-col gap-1 mt-3">
                                <li className="accordionItem">Extinção de Reserva</li>
                                <li className="accordionItem">2ª Via do Registo de Propriedade</li>
                                <li className="accordionItem">Transferência de Propriedade</li>
                                <li className="accordionItem">Outras alterações nos elementos do Registo de Propriedade</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Cartas de condução
                        </div>
                        <div className="px-6 collapse-content sm:px-10">
                            <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                                <input type="checkbox" />
                                <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                                    Renovação
                                </div>
                                <div className="px-6 collapse-content sm:px-10">
                                    <p className="text-gray-700 dark:text-gray-300">Pode proceder à revalidação da sua carta de condução durante os seis meses que antecedem o dia de validade.</p>
                                    <h2 className="mt-6 text-lg text-black dark:text-white">Condutores das Categorias A, B, B+E e das subcategorias A1 e B1:</h2>
                                    <ul className="flex flex-col gap-1">
                                        <li className="accordionItem">Aos 50, 60, 65, 70 e depois dos 70 anos é de 2 em 2 anos</li>
                                    </ul>
                                    <h2 className="mt-6 text-lg text-black dark:text-white">Condutores das Categorias C e C+E e das subcategorias C1, C1+E</h2>
                                    <ul className="flex flex-col gap-1">
                                        <li className="accordionItem">Aos 40, 45, 50, 55, 60, 65, 68 e depois dos 68 anos é de 2 em 2 anos</li>
                                    </ul>
                                    <h2 className="mt-6 text-lg text-black dark:text-white">Condutores das Categorias D e D+E e das subcategorias D1 e D1+E</h2>
                                    <ul className="flex flex-col gap-1">
                                        <li className="accordionItem">Aos 40, 45, 50, 55 e 60</li>
                                    </ul>
                                    <p className='mt-6 text-red-400'>A carta de condução não pode ser renovada com mais de seis meses de antecedêcia.</p>
                                </div>
                            </div>
                            <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                                <input type="checkbox" />
                                <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                                    Alteração
                                </div>
                                <div className="px-6 collapse-content sm:px-10">
                                    <p className="text-gray-700 dark:text-gray-300">Nos casos em que a carta de condução se tenha extraviado, tenha sido roubada ou esteja danificada deve requerer a emissão de uma segunda via.</p>
                                </div>
                            </div>
                            <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                                <input type="checkbox" />
                                <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                                  Emissão de 2ª via
                                </div>
                                <div className="px-6 collapse-content sm:px-10">
                                    <p className="text-gray-700 dark:text-gray-300">Deve fazer a substituição da carta de condução quando haja alterações de nome, morada, restrições, habilitação a nova categoria de veículo.</p>
                                    <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                                    <ul className="flex flex-col gap-1">
                                        <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                        <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                        <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                                <input type="checkbox" />
                                <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                                    Troca de Carta de Condução Militar ou Estrangeira
                                </div>
                                <div className="px-6 collapse-content sm:px-10">
                                    <p className="text-gray-700 dark:text-gray-300">Os titulares de “certificado de condução” emitidos pelas Forças Armadas e pelas Forças de Segurança, podem requerer a carta civil.</p>
                                    <p className="text-gray-700 dark:text-gray-300">Os titulares de carta de condução estrangeira podem requerer a emissão de carta de condução por troca de acordo com as correspondências entre as diferentes categorias de títulos de condução e país de origem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter />
            </main >
        </>
    );
};
export default Servicos;