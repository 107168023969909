import React from 'react';
import Newsletter from '../components/preInscricao';
import { useNavigate } from 'react-router-dom';

import { FaPhone } from "react-icons/fa6";
import { MdEmail, MdHome } from "react-icons/md";

const Contacto = () => {
    const navigate = useNavigate();

    return (
        <>
<main className='px-8 lg:px-24'>
    <h1 className="pt-6 text-center title">
        Entra em contacto
    </h1>

<div className='grid grid-cols-1 gap-3 mt-12 sm:grid-cols-2'>
    <div className='flex items-center gap-4 w-fit'>
        <div className='p-3 rounded-full bg-brandDarkBlue'>
            <FaPhone className="w-5 h-6 text-red-400" />
        </div>
        <a href="tel:+1269456134" className='text-black dark:text-white'>269 456 134</a>
    </div>
   
    <div className='flex items-center gap-4 w-fit'>
        <div className='p-3 rounded-full bg-brandDarkBlue'>
            <MdEmail className="w-6 h-6 text-red-400" />
        </div>
        <a href="mailto:isgrandola@gmail.com" className='text-black dark:text-white'>isgrandola@gmail.com</a>
    </div>
   
    <div className='flex items-center gap-4 w-fit'>
        <div className='p-3 rounded-full bg-brandDarkBlue'>
            <MdHome className="w-6 h-6 text-red-400" />
        </div>
        <a href="https://www.google.com/maps/search/?api=1&query=R.%20Dom%20Nuno%20%C3%81lvares%20Pereira%20162C,%207570-239%20Gr%C3%A2ndola" className='text-black dark:text-white' target="_blank" rel="noopener noreferrer">
            R. Dom Nuno Álvares Pereira 162C, 7570-239 Grândola
        </a>
    </div>
</div>


				<div className="flex flex-col mt-12 items-center-center sm:mt-6">
					<h3 className="text-center subtitle sm:text-left">Onde estamos</h3>
					<iframe
						className="w-[95%] sm:w-full mx-auto mt-2 sm:h-full sm:mx-0"
						style={{ height: "400px" }}
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196.02161389883068!2d-8.567061186654083!3d38.17864042528882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1bd3282a782e57%3A0xe23be10308f59726!2zQW5kYW7Dp2Fz!5e0!3m2!1spt-PT!2spt!4v1722610012962!5m2!1spt-PT!2spt"
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>



                <Newsletter />
            </main >
        </>
    );
};
export default Contacto;