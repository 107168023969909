import React from 'react';
import Newsletter from '../../components/preInscricao';

const Pesados = () => {

    return (
        <>
            <main>
                <h1 className="pt-6 text-center title">
                    Pesados
                </h1>
                <div className="flex flex-col max-w-4xl gap-5 mx-auto mt-12">
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria C
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Automóveis pesados de mercadorias</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Automóveis pesados de mercadorias, a que pode ser atrelado um reboque de peso bruto até 750kg</li>
                                <li className="accordionItem">Outros tractores agrícolas ou florestais com ou sem reboque sem restrição de peso</li>
                                <li className="accordionItem">Triciclos</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Carta de condução de categoria B</li>
                                <li className="accordionItem">Idade mínima 21 anos</li>
                                <li className="accordionItem">Aptidão física e mental</li>
                                <li className="accordionItem">Residir em Portugal</li>
                                <li className="accordionItem">Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className="accordionItem">Atestado Médico</li>
                                <li className="accordionItem">Relatório de exame psicológico favorável</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria C+E
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Pesados de mercadoria com reboque</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Conjunto de veículos compostos de veículo tractor da categoria C e reboque com peso bruto superior a 750kg</li>
                                <li className="accordionItem">Conjunto de veículos compostos por um automóvel ligeiro e reboque</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Carta de condução de categoria B</li>
                                <li className="accordionItem">Idade mínima 21 anos</li>
                                <li className="accordionItem">Aptidão física e mental</li>
                                <li className="accordionItem">Residir em Portugal</li>
                                <li className="accordionItem">Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className="accordionItem">Atestado Médico</li>
                                <li className="accordionItem">Relatório de exame psicológico favorável</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria D
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Automoveis pesados de passageiros</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Automoveis pesados de passageiros a que pode ser atrelado um reboque de peso bruto até 750kg</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Carta de condução de categoria B</li>
                                <li className="accordionItem">Idade mínima 21 anos</li>
                                <li className="accordionItem">Aptidão física e mental</li>
                                <li className="accordionItem">Residir em Portugal</li>
                                <li className="accordionItem">Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className="accordionItem">Atestado Médico</li>
                                <li className="accordionItem">Relatório de exame psicológico favorável</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="py-6 dark:bg-gray-800">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                        <h2 className='mb-4 subtitle'>A nossa frota</h2>
                        <div className="galleryContainer">
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/ligeiro1.png"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/ligeiro2.png"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/ligeiro3.png"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/ligeiro4.png"} />
                        </div>
                    </div>
                </div> */}
                <Newsletter />
            </main >
        </>
    );
};
export default Pesados;